import * as React from "react";

import { Link } from "gatsby";

import Layout from "../components/Layout";
import { getPageLang, getSlugToUrl } from "../plugins/nodeTool";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";

import * as style from "../scss/carDirectory.module.scss";
const CarDirectoryTemplate = ({ pageContext, location }) => {
  const { cars, navigations, localizations } = pageContext;
  const defaultImage = "../images/default-placeholder.png";
  return (
    <Layout
      navigations={navigations}
      location={location}
      localizations={localizations}
    >
      <div className="container">
        <div className="row">
          {cars.map((car, index) => (
            <Link
              to={getSlugToUrl(
                `car/${car.slug}`,
                getPageLang(location, localizations)
              )}
              key={index}
              className={`card mx-lg-1 gx-0 mx-0 col-12 col-md-6 col-lg-4 my-1 ${style.cardSelector}`}
            >
              <div className={style.images}>
                {(!car.images.length && (
                  <>
                    {
                      <StaticImage
                        src={defaultImage}
                        alt="alt"
                        width="auto"
                        height="auto"
                        className={`${style.directoryCarImg} ${style.zoomIn}`}
                      />
                    }
                  </>
                )) || (
                  <>
                    {
                      <GatsbyImage
                        image={getImage(car.images[0].localFile)}
                        alt="alt"
                        width="auto"
                        height="auto"
                        className={`${style.directoryCarImg} ${style.zoomIn}`}
                      />
                    }
                  </>
                )}
              </div>
              <div className={`p-2 ${style.infoCard}`}>
                <h2 className="col ">{car.title}</h2>
                <div className="row">
                  <div className="col">HKD ${car.price}</div>
                  <div className="col text-end">
                    {car.sold ? "sold" : "available"}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default CarDirectoryTemplate;
